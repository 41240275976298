define("discourse/plugins/discourse-n8n-wf/discourse/templates/connectors/composer-fields/hint", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="embed_workflow_tip" style="margin-top: 5px;margin-bottom: 5px;color: hsl(0, 0%, 40%);text-align: center;font-size: 0.9em;">
    💡 Tip: Insert your workflow by clicking '&lt;/&gt;' and pasting in <a
    href="https://docs.n8n.io/getting-started/key-components/workflow.html#sharing-a-workflow"
    target="_blank" style="color: hsl( 6.9, 100%, 67.6% );text-decoration: none;">the workflow's code</a>
  </div>
  
  */
  {
    "id": "YoGk574/",
    "block": "[[[10,0],[14,0,\"embed_workflow_tip\"],[14,5,\"margin-top: 5px;margin-bottom: 5px;color: hsl(0, 0%, 40%);text-align: center;font-size: 0.9em;\"],[12],[1,\"\\n  💡 Tip: Insert your workflow by clicking '</>' and pasting in \"],[10,3],[14,6,\"https://docs.n8n.io/getting-started/key-components/workflow.html#sharing-a-workflow\"],[14,\"target\",\"_blank\"],[14,5,\"color: hsl( 6.9, 100%, 67.6% );text-decoration: none;\"],[12],[1,\"the workflow's code\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-n8n-wf/discourse/templates/connectors/composer-fields/hint.hbs",
    "isStrictMode": false
  });
});