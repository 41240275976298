define("discourse/plugins/discourse-n8n-wf/lib/discourse-markdown/discourse-n8n-wf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  function getJSON(content) {
    let parsed;
    try {
      parsed = JSON.parse(content);
    } catch (e) {
      return null;
    }
    return parsed;
  }
  function isWorkflow(json) {
    return json && json['nodes'] && Array.isArray(json['nodes']) && json['nodes'][0]['type'];
  }
  function workflowCode(json) {
    console.log(json);
    return `<div class="workflow_preview">
            <n8n-demo workflow='${encodeURIComponent(JSON.stringify(json)).replaceAll("'", '%27')}' frame=true ></n8n-demo>
          </div>`;
  }
  function fence(state, startLine, endLine, silent) {
    var marker,
      len,
      params,
      nextLine,
      mem,
      token,
      markup,
      haveEndMarker = false,
      pos = state.bMarks[startLine] + state.tShift[startLine],
      max = state.eMarks[startLine];

    // if it's indented more than 3 spaces, it should be a code block
    if (state.sCount[startLine] - state.blkIndent >= 4) {
      return false;
    }
    if (pos + 3 > max) {
      return false;
    }
    marker = state.src.charCodeAt(pos);
    if (marker !== 0x7E /* ~ */ && marker !== 0x60 /* ` */) {
      return false;
    }

    // scan marker length
    mem = pos;
    pos = state.skipChars(pos, marker);
    len = pos - mem;
    if (len < 3) {
      return false;
    }
    markup = state.src.slice(mem, pos);
    params = state.src.slice(pos, max);
    if (marker === 0x60 /* ` */) {
      if (params.indexOf(String.fromCharCode(marker)) >= 0) {
        return false;
      }
    }

    // Since start is found, we can report success here in validation mode
    if (silent) {
      return true;
    }

    // search end of block
    nextLine = startLine;
    for (;;) {
      nextLine++;
      if (nextLine >= endLine) {
        // unclosed block should be autoclosed by end of document.
        // also block seems to be autoclosed by end of parent
        break;
      }
      pos = mem = state.bMarks[nextLine] + state.tShift[nextLine];
      max = state.eMarks[nextLine];
      if (pos < max && state.sCount[nextLine] < state.blkIndent) {
        // non-empty line with negative indent should stop the list:
        // - ```
        //  test
        break;
      }
      if (state.src.charCodeAt(pos) !== marker) {
        continue;
      }
      if (state.sCount[nextLine] - state.blkIndent >= 4) {
        // closing fence should be indented less than 4 spaces
        continue;
      }
      pos = state.skipChars(pos, marker);

      // closing code fence must be at least as long as the opening one
      if (pos - mem < len) {
        continue;
      }

      // make sure tail has spaces only
      pos = state.skipSpaces(pos);
      if (pos < max) {
        continue;
      }
      haveEndMarker = true;
      // found!
      break;
    }

    // If a fence has heading spaces, they should be removed from its inner block
    len = state.sCount[startLine];
    state.line = nextLine + (haveEndMarker ? 1 : 0);
    let json = getJSON(state.getLines(startLine + 1, nextLine, len, true));
    if (isWorkflow(json)) {
      token = state.push("html_raw", "", 0);
      token.content = workflowCode(json);
      token.markup = "";
      token.map = [startLine, state.line];
      return true;
    }
    token = state.push('fence', 'code', 0);
    token.info = params;
    token.content = state.getLines(startLine + 1, nextLine, len, true);
    token.markup = markup;
    token.map = [startLine, state.line];
    return true;
  }
  ;
  function setup(helper) {
    if (!helper.markdownIt) return;
    helper.registerPlugin(md => {
      md.block.ruler.before('fence', 'workflow', fence);
    });
  }
});